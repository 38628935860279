const defaultState: any = []
const reportGeneratorReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case 'GET_REPORT_GENERATOR':
            return { ...state, data: action.payload };
        case 'ADD_REPORT_GENERATOR':
            const addedItems = [...state.data, action.payload]
            return { ...state, data: addedItems };
        case 'UPDATE_REPORT_GENERATOR':
            const updatedItems = [...state.data]
                .map((i: any) => i.id === action.payload.id ? ({ ...action.payload }): ({ ...i }))
            return { ...state, data: [...updatedItems] };
        case 'DELETE_REPORT_GENERATOR':
            const filteredItems = [...state.data]
                .filter((item: any) => item.id !== action.payload)
                .map((i: any, index: number) => ({ ...i, id: index }))
            return { ...state, data: filteredItems };
        case 'PARSED_ITEM_LOADING':
            const parsedLoadingItem = [...state.data]
                .map((i: any) => i.id === action.payload ? ({ ...i, loading: true }): ({ ...i }))
            return { ...state, data: parsedLoadingItem };
        case 'PARSED_ITEM_SUCCESS':
            const index = action.payload.index;
            const parsedItem = [...state.data]
                .map((i: any) => {
                    const newParsedItemData = i.parsedItem?.data ? [...i.parsedItem?.data, ...action.payload.data.data] : [...action.payload.data.data]
                    return i.id === index
                    ? ({ ...i, parsedItem: { data: newParsedItemData }, loading: false })
                    : ({ ...i })
                })
            return { ...state, data: [...parsedItem] };
        case 'PARSED_ITEM_ERROR':
            const parsedErrorItem = [...state.data]
                .map((i: any) => i.id === action.payload ? ({ ...i, loading: false, error: true }): ({ ...i }))
            return { ...state, data: parsedErrorItem };
        default:
            return state;
    }
}

export default reportGeneratorReducer;
