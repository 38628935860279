const defaultState: any = {}

const reportLibraryReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case 'GET_REPORT_LIBRARY':
            return { ...state, data: action.payload };
        case 'UPDATE_REPORT_LIBRARY':
            const updatedItems = [...state.data]
                .map((i: any) => {
                    if(i.id === action.payload.id) {
                        return { ...action.payload }
                    } else {
                        return { ...i }
                    }
                })
            return { ...state, data: [...updatedItems] };
        case 'DELETE_REPORT_LIBRARY':
            const filteredItems = [...state.data]
                .filter((item: any) => item.id !== action.payload)
                .map((i: any, index: number) => ({ ...i, id: index }))
            return { ...state, data: filteredItems };
        default:
            return state;
    }
}

export default reportLibraryReducer;
