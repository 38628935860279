import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import './ReportGenerator.scss'
import {
  MotifIcon,
  MotifButton,
  MotifDropdownPortal,
  MotifDropdownItem,
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
  MotifProgressLoader
} from "@ey-xd/motif-react";
import {
  contentIcSave24px,
  fileIcFileDownload24px,
  navigationIcRefresh24px,
  editorIcAttachFile24px,
  actionIcHelpOutline24px,
  alertIcError24px,
  actionIcDelete24px,
  actionIcDone24px,
  fileIcCloudUpload24px
} from "@ey-xd/motif-react/assets/icons";
import ReportSection from './ReportSection/ReportSection';
import ReportDetails from './ReportDetails/ReportDetails';
import SaveReportModal from './SaveReportModal/SaveReportModal';
import {
  getReportGenerator,
  addReportGenerator,
  addParsedItem,
  deleteReportGenerator,
  udpateReportGenerator
} from '../../redux/actions/ReportGenerator/reportGenerator'
import { setReportDetails } from '../../redux/actions/ReportDetails/reportDetails'
import { generateReport } from '../../redux/actions/Report/report'
import { getReportSections, generateReportSections } from '../../redux/actions/ReportSections/reportSections'
import Input from '../../common/components/Input/Input'
import TextArea from '../../common/components/TextArea/TextArea'
import FileUpload from '../../common/components/FileUpload/FileUpload'
import Modal from '../../common/components/Modal/Modal'
import Toast from '../../common/components/Toast/Toast'
import MultiPageAdd from '../../assets/media/images/multiple-pages-add.png'
import { ReportGeneratorService } from '../../services/reportGenerator.service'

const ReportGenerator = () => {

  const defaultReportSections = [{
    id: 1,
    title: 'Section 1 - Background',
    prompt: "As a cyber threat intelligence analyst, your task is to generate a in-depth and detailed flash report or documentation on the  background and history. These reports should also include in-depth information about relevant attacks, including their nature, origin, potential impact and additional topics that relevant. This report or documentation should also encompass relevant attacks and provide links to additional information that can be found on external websites. The content of this report or documentation will be shared with external clients, so it is crucial that the information is accurate and comprehensive. Don't include any headers but provide very detailed version with all possible content.",
    temperature: 0.2,
  }, {
    id: 2,
    title: 'Section 2 - Technical Details',
    prompt: "As a Cyber Threat Intelligence Analyst, you are tasked with generating a comprehensive report on various technical details. Your report should include a thorough analysis of potential indicators of compromise and any other relevant content that could contribute to the understanding of the threat landscape. Consider various forms of cyber threats and their potential impact on different systems or networks. Your report should be detailed, clear, and should provide actionable recommendations for mitigating potential threats. Be sure to include any patterns or trends you observe, and provide your professional insight on potential future threats. Remember, your goal is to provide a resource that can guide decision-making to ensure the protection and integrity of cyber infrastructure. Your creativity and originality in presenting this complex information are highly encouraged.",
    temperature: 0.2,
  }, {
    id: 3,
    title: 'Section 3 - Execution Flow',
    prompt: "As a Cyber Threat Intelligence Analyst, you are tasked with generating a execution flow stages for given cyber-attack based on the article given in context.",
    temperature: 0.2,
  }, {
    id: 4,
    title: 'Section 4 - Tactics, Techniques, and Procedures',
    prompt: "Create Tactics vs Techniques mapping according to MITRE framework for given attack based on given context.  Give technique IDs and names for below tactics: 1 Intial access, 2 Execution, 3 Persistence, 4 Defense Evasion, 6 Discovery, 7 Lateral movement, 8 Exfiltration 9 Command and Control",
    temperature: 0.2,
  }, {
    id: 5,
    title: 'Section 5 - Recommendations',
    prompt: "Imagine you are a cyber threat intelligence analyst who has been asked to provide comprehensive recommendations to a multinational corporation. They want to improve their cybersecurity measures and need expert advice. Your recommendations should cover a wide range of threats, from phishing and malware attacks to data breaches and insider threats. Please provide a detailed analysis of each potential threat, along with your expert recommendations on how to prevent, detect, and respond to each one. Your suggestions should be practical, actionable, and tailored to a large corporation with diverse operations. Remember, your goal is to help the company improve its overall cybersecurity posture and resilience against potential cyber attacks.Dont include any other content apart from recommendations",
    temperature: 0.2,
  }, {
    id: 6,
    title: 'Section 6 - Conclusion',
    prompt: "Imagine you are a cyber threat intelligence analyst who has been asked to provide detailed conclusion based on given context",
    temperature: 0.2,
  }]

  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastData, setToastData] = useState<any>({})
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [domainName, setDomainName] = useState<any>('')
  const [documentSet, setDocumentSet] = useState('')

  const [urls, setUrls] = useState<any>('')
  const [text, setText] = useState<any>('')
  const [files, setFiles] = useState<any>([])

  const [parsedId, setParsedId] = useState<any>(0)
  const reportGenerator = useSelector((state: any) => state?.reportGenerator?.data) || []
  const reportSections = useSelector((state: any) => state?.reportSections?.data) || []
  const reportDetails = useSelector((state: any) => state?.reportDetails?.data) || {}
  const shouldShowReportSections = !!reportSections?.find((i: any) => i?.content)

  useEffect(() => {
    dispatch(getReportGenerator([]))
    dispatch(getReportSections(defaultReportSections))
  }, [])

  const resetBuilder = () => {
    setDomainName('')
    setParsedId(0)
    setUrls('')
    setText('')
    setFiles([])
    setDocumentSet('')
    dispatch(getReportGenerator([]))
    dispatch(setReportDetails({}))
    dispatch(getReportSections(defaultReportSections))
  }

  const toggleOpen = () => {
    setOpen(!open);
  }

  const addDatasourceInput = (type: string) => {
    let item;
    const id = reportGenerator.length;
    switch(type) {
      case 'Link':
        item = {
          id,
          type: 'Link',
        }
        break;
      case 'File':
        item = {
          id,
          type: 'File',
        }
        break;
      case 'Text':
        item = {
          id,
          type: 'Text',
        }
        break;
    }
    dispatch(addReportGenerator(item))
    closeDropdown();
  }

  const deleteDatasourceInput = (id: number) => {
    dispatch(deleteReportGenerator(id))
  }

  const closeDropdown = () => {
    setOpen(false);
  }

  const getInfoText = (type: string) => {
    switch(type) {
      case 'Link':
        return 'You can add multiple links by separating them with comma'
      case 'File':
        return 'You can add multiple files by dragging and dropping'
      case 'Text':
        return 'Please type or paste the text here'
    }
  }

  const getDisabledUpload = (type: string) => {
    if(!domainName) {
      return true
    }
    switch(type) {
      case 'Link':
        return !urls
      case 'File':
        return !files
      case 'Text':
        return !text
    }
  }
  const handleDrop = (event: any, index: any) => {
    const updatedItem = reportGenerator.find((i: any) => i.id === index)
    setFiles(event)
    const fileDetails = event.map((file: any) => ({ name: file.name, size: file.size }))
    dispatch(udpateReportGenerator({ ...updatedItem, fileDetails }))
  }
  const flexCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const parseData = (type: any, itemId: any) => {
    let newParsedId = parsedId + 1;
    setParsedId(newParsedId)
    let updatedDocumentSet: any = documentSet;
    if(!updatedDocumentSet) {
      const uniqueId = uuidv4()
      updatedDocumentSet = `${domainName}_${uniqueId}`
      setDocumentSet(updatedDocumentSet)
    }
    switch(type) {
      case 'Link':
        const formDataLink = new FormData()
        formDataLink.append('document_id', `${newParsedId}`)
        formDataLink.append('document_set', updatedDocumentSet)
        formDataLink.append('urls', urls)
        dispatch(addParsedItem(formDataLink, itemId))
        break;
      case 'File':
        let newParsedIdForFile = parsedId;
        files?.forEach((file: any, index: any) => {
          newParsedIdForFile = parsedId + index + 1;
          const formData = new FormData();
          formData.append('document_id', `${newParsedIdForFile}`);
          formData.append('document_set', updatedDocumentSet);
          formData.append('document_name', file.name);
          formData.append('file', file);
          dispatch(addParsedItem(formData, itemId))
        })
        setParsedId(newParsedIdForFile)
        break;
      case 'Text':
        const formDataText = new FormData()
        formDataText.append('document_id', `${newParsedId}`)
        formDataText.append('document_set', updatedDocumentSet)
        formDataText.append('text', text)
        dispatch(addParsedItem(formDataText, itemId))
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    const sectionLoading = reportSections?.reduce((acc: any, curr: any) => curr?.loading || acc, false)
    setShowSpinner(sectionLoading)
  }, [reportSections])

  const handleGenerateReport = () => {
    let requestParams: any = []
    setShowSpinner(true)
    reportGenerator.forEach((item: any) => {
      if (item.parsedItem) {
        requestParams = [...requestParams, ...item.parsedItem.data]
      }
    })
    dispatch(generateReport(requestParams)).then(() => {
      reportSections.forEach((section: any) => {
        generateSection(section?.id, section?.prompt, section?.temperature * 100)
      })
    }).catch(() => {
      setShowSpinner(false)
      setToastVisible(true)
      setToastData({
        header: "Failed",
        message: "Unable to generate report. Please try again.",
        variant: "error"
      })
    })
  }
  const droppedFileDetails = (files: any) => files
    .map((file: any, index: number) => `${index + 1}. Name: ${file.name}\n Size: ${file.size}kb`)
    .join('\n')

  const generateSection = (id: number, prompt: string, randomness: number) => {
    const requestParams = {
      "Prompt": prompt,
      "Domain": domainName,
      "document_set_id": documentSet,
      "temperature": randomness / 100,
      "section_id": id
    }
    dispatch(generateReportSections(requestParams, id))
  }

  const appendComments = (content: any) => {
    const dataToParse = content?.domains && content?.domains[0]?.subDomains
    if(!dataToParse) {
      return ''
    }
    let comments = ''
    dataToParse.forEach((d: any) => {
      d.questions.forEach((q: any) => {
        comments += q.question ? q.question + '\n' : ""
        comments += q.comments ? q.comments?.join('\n') + "\n" : ""
        comments += q.references ? "References:\n" + q.references?.join('\n') + "\n" : ""
      })
    })
    return comments
  }

  const downloadReportRequestData = () => {
    let result: any = []
    const uuid = uuidv4()
    reportSections?.forEach((r: any) => {
      if(r?.content?.domains) {
        result.push(r?.content?.domains[0])
      }
    })
    const dateTimeStamp = new Date().toISOString()
    const mockRequestData = {
      "templateName": "Cyber_Report_Template",
      "reportDetails": {
        "reportName": `Emerging Threat Advisory`,
        "date": dateTimeStamp,
        "description": "",
        "outputFormat": ".pptx",
        "assessmentName": "Assessment" + " " + domainName,
      },
      domains: [...result],
      uuid
    }
    return mockRequestData;
  }
  const downloadReport = async () => {
    setShowSpinner(true)
    fetch(`${process.env.REACT_APP_API_URL}/generate_report`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(downloadReportRequestData())
  }).then(resp => {
    if(resp.ok) {
      return resp.arrayBuffer()
    } else {
      setToastVisible(true)
      setToastData({
        header: "Failed",
        message: "Unable to download Report. Please try again.",
        variant: "error"
      })
      throw new Error('Something went wrong')
    }
  }).then(resp => {

      // set the blog type to final pdf
      const file = new Blob([resp], {type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'});
      const dateTimeStamp = new Date().toISOString()
      const reportName = `Emerging Threat Advisory_${domainName}_${dateTimeStamp}`

      // process to auto download it
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = reportName + ".pptx";
      link.click();
      dispatch(setReportDetails({
        reportName: reportName,
        noOfInputs: parsedId,
        date: dateTimeStamp
      }))
    })
    .catch((e: any) => console.error(e))
    .finally(() => {setShowSpinner(false)})
  }

  return (
    <div className="reportGeneratorWrapper">
      <MotifProgressLoader show={showSpinner} />
      <div className="breadCrumbs">Reports</div>
      <div className="heading">
        <div className="title">Report Generator</div>
        <div className="description">Descriptive text that describes your page or content.</div>
      </div>
      <div className="report__generator__content">
        <div className="inputWrapper">
          <div className="inputWrapper__heading">
            <div className="inputWrapper__heading__title">Provide data sources </div>
            <div className="inputWrapper__heading__description">
              <div>This text will explain what data sources can be provided and how.</div>
            </div>
            <div className="actions">
              <MotifButton
                size="medium"
                variant="text"
                type="button"
                className="saveReport"
                onClick={() => {setModalVisible(true)}}
              >
                <MotifIcon src={contentIcSave24px} />
                <span className="buttonText">Save Report</span>
              </MotifButton>
              <MotifButton
                size="medium"
                variant="text"
                type="button"
                className="downloadReport"
                disabled={!reportSections.find((section: any) => section?.content?.domains)}
                onClick={downloadReport}
              >
                <MotifIcon src={fileIcFileDownload24px} />
                <span className="buttonText">Download Report</span>
              </MotifButton>
            </div>
          </div>
        </div>
        <div className="datasourceWrapper" style={!reportGenerator.length ? flexCenter : {}}>
          {!reportGenerator.length && (
            <div className="addDatasourceWrapper">
              <img src={MultiPageAdd} className="saveIcon"></img>
              <div className="addDatasourceWrapper__title">Start with adding data sources</div>
              <div className="addDatasourceWrapper__desc">Add Links, text files or paste texts.</div>
              <MotifDropdownPortal
                open={open}
                trigger={
                  <MotifButton
                    size="medium"
                    variant="primary"
                    type="button"
                    className="addDatasource"
                    onClick={toggleOpen}
                  >
                    + Add Data Source
                  </MotifButton>
                }
              >
                <MotifDropdownItem onClick={() => addDatasourceInput('Link')}>Link</MotifDropdownItem>
                <MotifDropdownItem onClick={() => addDatasourceInput('File')}>File</MotifDropdownItem>
                <MotifDropdownItem onClick={() => addDatasourceInput('Text')}>Text</MotifDropdownItem>
              </MotifDropdownPortal>
            </div>
          )}
          {!!reportGenerator.length && (
            <div className="datasourceItemsWrapper">
                <div className='domainNameWrapper'>
                  <Input
                    value={domainName}
                    onChange={(e: any) => setDomainName(e.target.value)}
                    label={<label>Domain Name<span style={{ color: 'red' }}>*</span></label>}
                  />
                  <div style={{ marginTop: -8, marginBottom: 8 }}>e.g. BlackTech, DarkAngels etc</div>
                </div>
              {reportGenerator.map((item?:any, index?:any) => {

                const shouldShowUpload = !item?.parsedItem && !item?.loading
                const shoulShowLoading = item.loading
                const shouldShowProcessed = item?.parsedItem && !item?.error
                const shouldShowError = item?.error

                return (
                  <div className="datasourceItemWrapper" key={index}>
                    <div className="datasourceItem__type">
                      <div className="inputType">
                        {item.type === "Link" && (
                          <Input value={urls} onChange={(e: any) => setUrls(e.target.value)} label={'Link'}></Input>
                        )}
                        {item.type === "File" && !item.fileDetails && (
                          <FileUpload handleDrop={handleDrop} fileIndex={index} />
                        )}
                        {
                          item.type === "File" && item.fileDetails && (
                            <TextArea
                              label={"File Information"}
                              rows={item.fileDetails.length * 2}
                              value={droppedFileDetails(item.fileDetails)}
                            />
                          )
                        }
                        {item.type === "Text" && (
                          <TextArea value={text} onChange={(e: any) => setText(e.target.value)} label="Text" rows="4"></TextArea>
                        )}
                      </div>
                      <div className="linkInfo">
                        <MotifIcon
                          src={actionIcHelpOutline24px}
                          title={getInfoText(item?.type)}
                        />
                      </div>
                      <div className="process">
                        <div className="processState">
                          {
                            shouldShowUpload && (
                              <>
                                <MotifButton
                                  size="medium"
                                  variant="text"
                                  type="button"
                                  disabled={getDisabledUpload(item?.type)}
                                  onClick={() => parseData(item.type, item?.id)}
                                >
                                  <MotifIcon src={fileIcCloudUpload24px} className="uploadIcon" />
                                  <span className="buttonText">Upload</span>
                                </MotifButton>
                              </>
                            )
                          }
                          {
                            shoulShowLoading && (
                              <>
                                <div className="processingSpinner"></div>
                                <span className="buttonText">In Progress</span>
                              </>
                            )
                          }
                          {
                            shouldShowProcessed && (
                              <>
                                <MotifIcon src={actionIcDone24px} className="processedIcon"/>
                                <span className="buttonText">Processed</span>
                              </>
                            )
                          }
                          {
                            shouldShowError && (
                              <>
                                <MotifIcon src={alertIcError24px} className="errorState" />
                                <span style={{ color: 'red', marginRight: 8 }}>Error</span>
                              </>
                            )
                          }
                        </div>
                      </div>
                      <div className="deleteDatasource">
                        <MotifButton
                          size="medium"
                          variant="text"
                          type="button"
                          onClick={() => deleteDatasourceInput(item.id)}
                        >
                          <MotifIcon src={actionIcDelete24px} />
                          <span className="buttonText">Delete</span>
                        </MotifButton>
                      </div>
                    </div>
                  </div>
                )
              })}
              <MotifDropdownPortal
                open={open}
                trigger={
                  <MotifButton
                    size="medium"
                    variant="primary"
                    type="button"
                    className="addDatasource"
                    disabled={reportGenerator.length >= 3}
                    onClick={toggleOpen}
                  >
                    + Add Data Source
                  </MotifButton>
                }
              >
                <MotifDropdownItem
                  onClick={() => addDatasourceInput('Link')}
                  disabled={!!reportGenerator?.find((item: any) => item.type === 'Link')}
                >
                  Link
                </MotifDropdownItem>
                <MotifDropdownItem
                  disabled={!!reportGenerator?.find((item: any) => item.type === 'File')}
                  onClick={() => addDatasourceInput('File')}
                >
                  File
                </MotifDropdownItem>
                <MotifDropdownItem
                  disabled={!!reportGenerator?.find((item: any) => item.type === 'Text')}
                  onClick={() => addDatasourceInput('Text')}
                >
                  Text
                </MotifDropdownItem>
              </MotifDropdownPortal>
            </div>
          )}
        </div>
        <div className="submitWrapper">
          <MotifButton
            size="medium"
            variant="text"
            type="button"
            disabled={!reportGenerator.length}
            onClick={resetBuilder}
          >
            <MotifIcon src={navigationIcRefresh24px} />
            <span className="buttonText">Reset Builder</span>
          </MotifButton>
          <MotifButton
            size="medium"
            variant="primary"
            type="button"
            disabled={!documentSet}
            onClick={handleGenerateReport}
          >
            Generate Report
          </MotifButton>
        </div>
        {reportDetails?.reportName && <ReportDetails reportDetails={reportDetails}/>}
        {shouldShowReportSections && (
          <div className="reportSectionWrapper">
            <div className="reportSectionHeader">Report Sections</div>
            <div className="reportSections">
              {reportSections?.map((section: any, index: number) => {
                return (
                  <div key={index}>
                    <MotifAccordion alignIconRight useChevronIcon>
                        <MotifAccordionTrigger>{section?.title}</MotifAccordionTrigger>
                        <MotifAccordionContent>
                          <ReportSection
                            id={section?.id}
                            defaultPrompt={section?.prompt}
                            defaultContent={appendComments(section?.content)}
                            defaultRandomness={section?.temperature}
                            loading={section?.loading}
                            generateSection={generateSection}
                          />
                          {section?.error &&
                            <div
                              style={{ color: 'red', padding: 8 }}
                            >
                              Generate section failed...!!! Please try to generate this particular section again by clicking on "Generate Section" button.
                            </div>
                          }
                        </MotifAccordionContent>
                    </MotifAccordion>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <SaveReportModal visibility={modalVisible} setVisibility={setModalVisible} />
      <Toast
          visible={toastVisible}
          setVisible={setToastVisible}
          children={
            <div>
              <div className={'toastHeader'}>{toastData?.header}</div>
              <div className={'toastContent'}>{toastData?.message}</div>
            </div>
          }
          variant={toastData?.variant}
      />
    </div>
  )
}

export default ReportGenerator
