import { Dispatch } from 'redux'

export const getReportLibrary: any = (dummyData: any) => (dispatch: Dispatch) => {
    dispatch({ type: 'GET_REPORT_LIBRARY', payload: dummyData});
}

export const udpateReportLibrary: any = (item: any) => (dispatch: Dispatch) => {
    dispatch({ type: 'UPDATE_REPORT_LIBRARY', payload: item});
}

export const deleteReportLibrary: any = (index: any) => (dispatch: Dispatch) => {
    dispatch({ type: 'DELETE_REPORT_LIBRARY', payload: index});
}
