import React, { useState } from 'react'
import { MotifFormField, MotifSearch } from '@ey-xd/motif-react'

const Search = () => {

    const [value, setValue] = useState('')
    return (
        <MotifFormField>
            <MotifSearch
                aria-label="Search"
                id="states-search"
                items={[
                    "111",
                    "112",
                    "113",
                    "121",
                    "122",
                    "123",
                    "131",
                    "132",
                    "133",
                ]}
                onChange={(e: any) => setValue(e.target.value)}
                onSearchButtonClick={function noRefCheck(){}}
                onSelect={(val: any) => setValue(val)}
                placeholder="Search for..."
                value={value}
            />
        </MotifFormField>
    )
}

export default Search