import React, { useState, useEffect } from 'react'
import './ReportLibrary.scss'
import { useDispatch, useSelector } from 'react-redux';
import { MotifTable, MotifTableCellRenderer, MotifIcon, MotifButton, MotifDropdownPortal, MotifDropdownItem } from '@ey-xd/motif-react'
import { actionIcDelete24px, fileIcFileDownload24px, imageIcEdit24px, alertIcErrorOutline24px, actionIcHelpOutline24px } from "@ey-xd/motif-react/assets/icons";
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/components/tab-navigation'
import { getReportLibrary, udpateReportLibrary, deleteReportLibrary } from '../../redux/actions/ReportLibrary/reportLibrary'
import DeleteModal from './DeleteModal/DeleteModal'
import EditModal from './EditModal/EditModal'
import Search from '../../common/components/Search/Search'
import Table from '../../common/components/Table/Table'
import Actions from './Actions/Actions'

function ReportLibrary() {

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState(-1)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editItemlId, setEditItemId] = useState(-1)

  const dispatch = useDispatch()
  const reportLibrary = useSelector((state: any) => state?.reportLibrary?.data) || []

  const dummyData = [{
    id: 1,
    reportName: 'Report 1',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }, {
    id: 2,
    reportName: 'Report 2',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }, {
    id: 3,
    reportName: 'Report 3',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }, {
    id: 4,
    reportName: 'Report 4',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }, {
    id: 5,
    reportName: 'Report 5',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }, {
    id: 6,
    reportName: 'Report 6',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }, {
    id: 7,
    reportName: 'Report 7',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }, {
    id: 8,
    reportName: 'Report 8',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }, {
    id: 9,
    reportName: 'Report 9',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }, {
    id: 10,
    reportName: 'Report 10',
    dateCreated: '14 February 2024',
    dateModified: '28 February 2024',
    owner: 'John Doe'
  }]

  const handleDeleteReport = () => {
    const deleteId = reportLibrary[deleteItemId]?.id
    dispatch(deleteReportLibrary(deleteId))
    setDeleteModalVisible(false)
    setDeleteItemId(-1)
  }

  const handleUdpateReport = (item: any) => {
    const newItem = { ...item }
    dispatch(udpateReportLibrary(newItem))
    setDeleteModalVisible(false)
    setEditItemId(-1)
  }

  useEffect(() => {
    dispatch(getReportLibrary(dummyData))
  }, [])

  return (
    <div className="reportLibraryWrapper">
      <div className="breadCrumbs">Reports</div>
      <div className="heading">
        <div className="title">Report Library</div>
        <div className="description">Descriptive text that describes your page or content.</div>
      </div>
      <div className="reportLibrary__content">
        <MotifTabNavigation defaultActiveKey={0}>
          <MotifTabControl>
            My Reports
          </MotifTabControl>
        </MotifTabNavigation>
        <Search />
        <div className='reportLibrary__tableWrapper'>
          {reportLibrary?.length > 0 && (
            <MotifTable
              columnDefs={[
                {
                  field: 'reportName',
                  headerName: 'Report Name',
                  sortable: true,
                  width: 300
                },
                {
                  field: 'dateCreated',
                  headerName: 'Date Created',
                  sortable: true,
                  width: 200
                },
                {
                  field: 'dateModified',
                  headerName: 'Date Modified',
                  sortable: true,
                  width: 200
                },
                {
                  field: 'owner',
                  cellRenderer: 'ownerCellRenderer',
                  headerName: 'Owner',
                  sortable: true,
                  width: 300
                },
                {
                  field: 'actions',
                  cellRenderer: 'actionsCellRenderer',
                  headerName: 'Actions',
                  sortable: true,
                  className: 'testActions'
                }
              ]}
              components={{
                actionsCellRenderer: (p: any) => (
                  <Actions
                    rowIndex={p.rowIndex}
                    setDeleteItemId={setDeleteItemId}
                    setEditItemId={setEditItemId}
                    setEditModalVisible={setEditModalVisible}
                    setDeleteModalVisible={setDeleteModalVisible}
                  />
                ),
                ownerCellRenderer: (p: any) => {
                  return (
                    <img
                      src={'https://s3-alpha-sig.figma.com/img/2f11/9087/0d753151f58657595136f67c584b5c8c?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RK4Y~Ixv73GJDjKIwCdPpGpAi7heQAWlcd1i0GbSNxwByVA-JxXqB4TuWL8Cd~w~laLeenYVS1NBNkYmlZE07i2CYvj9C3IKXiSP39IaUVjM-ga2fRCvzo0QoxJeOHL0quSEUybiWyLvxGwb3tcWRpJYRa4d~-FJimFzjLWu9Ep40x7hKPDa39j9fKc5zPyxK47xGHueRy~pNL6ytmNaBmFmkRJDmMiVDixqSRjmJN8XIhTQDiOopu~ZhEL5-VNREZ8XNzRAkBfzzJQlmPWOWOnPQOIh2Am6GtVfbLXEwHLkcPC1Rfuhgc0wX~a0m7OGLD0UaEEbWn9~onjY5~n45A__'}
                      width={36}
                      height={36}
                      style={{ borderRadius: 50, background: "#C7B9DA" }}
                    />
                  )
                }
              }}
              onFilterChanged={function noRefCheck(){}}
              onGridReady={function noRefCheck(){}}
              onSortChanged={function noRefCheck(){}}
              pagination
              paginationPageSize="3"
              rowData={reportLibrary}
              suppressCellFocus
              suppressRowClickSelection
            />
          )}
        </div>
      </div>
      <DeleteModal
        report={reportLibrary[deleteItemId] || {}}
        isVisible={deleteModalVisible}
        setIsVisible={setDeleteModalVisible}
        onConfirmClick={handleDeleteReport}
      />
      <EditModal
        report={reportLibrary[editItemlId] || {}}
        isVisible={editModalVisible}
        setIsVisible={setEditModalVisible}
        onConfirmClick={handleUdpateReport}
      />
    </div>
  )
}

export default ReportLibrary
