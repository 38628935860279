import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import SideBar from '../components/SideBar/SideBar'
import Header from 'components/Header/Header';
import ReportGenerator from 'components/ReportGenerator/ReportGenerator';
import ReportLibrary from 'components/ReportLibrary/ReportLibrary';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import './AppLayout.scss'

const AppLayout = () => {
    const sideCollapsed = useSelector((state: any) => state?.sideBar?.collapsed) || false;

    return (
        <div className='wrapper'>
            <ErrorBoundary>
                <Header />
            </ErrorBoundary>
            <ErrorBoundary>
                <div className="content">
                    <SideBar collapsed={sideCollapsed}></SideBar>
                    <div className="main">
                        <Routes>
                            <Route
                                path={'/'}
                                element={<Navigate to={'/reportGenerator'} />}
                            />
                            <Route path={`reportGenerator/*`} element={<ReportGenerator />} />
                            <Route path={`reportLibrary/*`} element={<ReportLibrary />} />
                        </Routes>
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    )
}

export default AppLayout;
