import React, { useState, useEffect } from 'react'
import { MotifIcon, MotifToast, MotifNotification } from '@ey-xd/motif-react'
import { alertIcErrorOutline24px } from "@ey-xd/motif-react/assets/icons";
import Modal from '../../../common/components/Modal/Modal'
import Toast from '../../../common/components/Toast/Toast'
import './DeleteModal.scss'

const DeleteModal = ({ isVisible, setIsVisible, report, onConfirmClick }: any) => {

    const [toastVisible, setToastVisible] = useState(false)

    const handleonfirmClick = () => {
        onConfirmClick && onConfirmClick()
        setToastVisible(true)
    }

    useEffect(() => {
        if(toastVisible) {
            setTimeout(() => {
                setToastVisible(false)
            }, 2000)
        }
    }, [toastVisible])

    return (
        <div>
            <Modal
                isVisible={isVisible}
                setVisible={setIsVisible}
                isDeleteModal
                onConfirmClick={handleonfirmClick}
                onCancelClick={() => setIsVisible(false)}
                headerContent={
                    <div className="deleteModalHeader" id="dialog-title">
                        <MotifIcon
                            src={alertIcErrorOutline24px}
                            className="deleteModalHeader__icon"
                        />
                        <div>Delete {report.reportName}?</div>
                    </div>
                }
                bodyContent={
                    <div className='deleteModalContent'>
                        <div className='deleteModalContent__text'>
                            Are you sure you want to delete this report? This action cannot be undone.
                        </div>
                    </div>
                }
            />
            <Toast
                visible={toastVisible}
                setVisible={setToastVisible}
                children={
                    <div>
                        <div className={'toastHeader'}>Successfully deleted</div>
                        <div className={'toastContent'}>Your have successfully deleted report. It won’t be available in Report’s Library any more.</div>
                    </div>
                }
                variant='success'
            />
        </div>
    )
}

export default DeleteModal
