import { Dispatch } from 'redux'
import { ReportGeneratorService } from '../../../services/reportGenerator.service';

export const getReportGenerator: any = (reportGenerator: any) => (dispatch: Dispatch) => {
    dispatch({ type: 'GET_REPORT_GENERATOR', payload: reportGenerator });
}

export const addReportGenerator: any = (item: any) => (dispatch: Dispatch) => {
    dispatch({ type: 'ADD_REPORT_GENERATOR', payload: item });
}

export const deleteReportGenerator: any = (index: any) => (dispatch: Dispatch) => {
    dispatch({ type: 'DELETE_REPORT_GENERATOR', payload: index });
}

export const udpateReportGenerator: any = (item: any) => (dispatch: Dispatch) => {
    dispatch({ type: 'UPDATE_REPORT_GENERATOR', payload: item });
}

export const addParsedItem: any = (item: any, index: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: 'PARSED_ITEM_LOADING', payload: index });
        const res = await ReportGeneratorService.addParsedItem(item);
        dispatch({ type: 'PARSED_ITEM_SUCCESS', payload: { data: res.value, index } });
    } catch(e: any) {
        dispatch({ type: 'PARSED_ITEM_ERROR', payload: index });
    }
}
