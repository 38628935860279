import { useState } from 'react'
import { MotifIcon, MotifButton, MotifDropdownPortal, MotifDropdownItem } from '@ey-xd/motif-react'
import { actionIcDelete24px, fileIcFileDownload24px, imageIcEdit24px, alertIcErrorOutline24px, actionIcHelpOutline24px } from "@ey-xd/motif-react/assets/icons";
import './Actions.scss'

const Actions = ({
    rowIndex,
    setEditModalVisible,
    setDeleteModalVisible,
    setDeleteItemId,
    setEditItemId
}: any) => {
    const [downloadVisible, setDownloadVisible] = useState(false)

    return (
        <div className="reportLibraryActions">
            <MotifButton
                size="small"
                variant="text"
                type="button"
                onClick={() => {
                    setDeleteItemId(rowIndex)
                    setDeleteModalVisible(true)}
                }
            >
                <MotifIcon src={actionIcDelete24px} style={{ width: 20 }}/>
            </MotifButton>
            <MotifDropdownPortal
                open={downloadVisible}
                trigger={
                    <MotifButton
                        size="small"
                        variant="text"
                        type="button"
                        onClick={() => {
                            setEditItemId(rowIndex)
                            setDownloadVisible(!downloadVisible)}
                        }
                    >
                        <MotifIcon src={fileIcFileDownload24px} style={{ width: 20 }} />
                    </MotifButton>
                }
            >
                <MotifDropdownItem onClick={() => setDownloadVisible(false)}>doc. file</MotifDropdownItem>
                <MotifDropdownItem onClick={() => setDownloadVisible(false)}>pdf. file</MotifDropdownItem>
            </MotifDropdownPortal>
            <MotifButton
                size="small"
                variant="text"
                type="button"
                onClick={() => {
                    setEditModalVisible(true)
                    setEditItemId(rowIndex)
                }}
            >
                <MotifIcon src={imageIcEdit24px} style={{ width: 20 }} />
            </MotifButton>
        </div>
    )
}

export default Actions
