
import './App.scss';

import React, { FC } from 'react';
import { MotifButton } from '@ey-xd/motif-react';
import AppLayout from './layout/AppLayout';
import configureStore from '../src/redux/core/store.config';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

const App: FC = () => (
    <Provider store={configureStore()}>
        <BrowserRouter>
            <AppLayout />
        </BrowserRouter>
    </Provider>
);

export default App;
